import { BodyText, Heading } from 'components/atoms/typography';

import { AppPageProps } from 'gatsby/types';
import ContactForm from 'components/organisms/forms/Contact';
import { ContactUsPageQuery } from 'gatsby/graphqlTypes';
import FullWidthImage from '@svelte/reactify/FullWidthImage';
import PageContentLayout from 'components/layouts/PageContentLayout';
import React from 'react';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import { graphql } from 'gatsby';
import { useSocialMediaLinksIcon } from 'gatsby/graphql/useSocialMediaLogos';

export { Head } from 'components/atoms/Head';

export type Props = AppPageProps<ContactUsPageQuery>;

export const query = graphql`
  query ContactUsPage {
    c: strapiContactUsPage {
      pageHeading
      mainText
      formTitle
      formSuccessTitle
      formSuccessText
      image {
        ...CdnImageData
      }
    }
  }
`;

const ContactUs: React.FC<Props> = props => {
  const socialMediaLinks = useSocialMediaLinksIcon();
  const data = props.data.c;
  const pageContext = props.pageContext;
  const image = data?.image;
  if (!data || !image) {
    throw Error('no data');
  }

  return (
    <PageContentLayout footerNavigation={pageContext.footerNavigation}>
      <FullWidthImage
        display="block lg:hidden"
        data={image}
        height="h-rhythm10 md:h-rhythm11"
        eager
      />
      <div className="min-h-screen lg:flex">
        <div className="px-4 py-rhythm4 sm:px-6 lg:px-12 lg:pt-rhythm6 xl:px-20 2xl:px-32">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <Heading level={1}>{data.pageHeading}</Heading>
              <BodyText>{data.mainText}</BodyText>
            </div>

            <div className="mt-rhythm4">
              <Heading level={2}>Social Media</Heading>

              <div className="grid grid-cols-3 gap-3">
                {socialMediaLinks.map(item => (
                  <div key={item.name}>
                    <a
                      className="inline-flex w-full justify-center rounded-md border border-primary px-4 py-rhythm-1 text-sm font-medium shadow-sm hover:bg-pearl-200"
                      key={item.name}
                      href={item.href}
                      onClick={item.handler}
                    >
                      <span className="sr-only">
                        Contact us via {item.name}
                      </span>
                      <img
                        className="h-6 w-6"
                        src={item.iconHref}
                        alt={`${item.name} icon`}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-rhythm4">
              <ContactForm
                successTitle={data.formSuccessTitle}
                successBody={data.formSuccessText}
                title={data.formTitle}
                reason="GENERAL"
              />
            </div>
          </div>
        </div>

        <ResponsiveImage
          width="flex-1"
          height="h-auto"
          display="hidden lg:block"
          data={image}
          eager
        />
      </div>
    </PageContentLayout>
  );
};

export default ContactUs;
